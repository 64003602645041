<template>
  <v-container fluid class="pa-0">
    <v-dialog v-model="dialog" scrollable max-width="500px" v-if="showItem">
      <v-card>
        <v-card-text>{{ showItem.description }}</v-card-text>
      </v-card>
    </v-dialog>
    <v-skeleton-loader :loading="status === 'fetching'" type="table">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t("billPreview.date") + "/" + $t("billPreview.time") }}
            </th>
            <th v-if="$vuetify.breakpoint.smAndUp">
              {{ $t("billPreview.description") }}
            </th>
            <th class="text-right">{{ $t("billPreview.amount") }}</th>
            <th v-show="$vuetify.breakpoint.xs"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="
              index +
              $helpers.formatDate(
                item.dateObj,
                $config['bill_item_date_format' + $config.format24Or12]
              )
            "
          >
            <td v-if="$config.HIDE_BILL_ITEM_TIME">
              {{
                $helpers.formatDate(
                  item.dateObj,
                  $config["bill_item_date_format" + $config.format24Or12]
                )
              }}
            </td>
            <td v-else>
              {{
                $helpers.formatDate(
                  item.dateObj,
                  $config["bill_item_date_format" + $config.format24Or12]
                )
              }}
              {{
                $helpers.formatDate(
                  item.dateObj,
                  $config["bill_item_time_format" + $config.format24Or12]
                )
              }}
            </td>
            <td
              v-if="$vuetify.breakpoint.smAndUp"
              style="overflow: hidden; text-overflow: ellipsis"
            >
              {{ item.description }}
            </td>
            <td
              class="text-right"
              v-html="$config.currency_short + ' ' + item.amount.toFixed(2)"
            ></td>
            <td v-show="$vuetify.breakpoint.xs" class="text-right">
              <v-btn
                @click="
                  showInfoModalItem = item;
                  showInfoModal = true;
                "
                icon
              >
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-divider></v-divider>
      <v-simple-table>
        <tbody>
          <tr>
            <td class="text-right">
              <strong>
                {{ $t("billPreview.sum") }}
                {{ sum | currency($store.state.session.currencyConfig) }}
              </strong>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-divider></v-divider>
    </v-skeleton-loader>
    <v-container
      class="pa-4"
      v-if="!$store.state.session.settings.webcheckin.disableCheckout"
    >
      <v-spacer></v-spacer>
      <v-btn
        @click="$store.commit('SET_SHOW_CHECKOUT_CONFIRMATION', true)"
        block
        color="primary"
        >{{ $t("login.checkout") }}</v-btn
      >
    </v-container>
    <v-dialog v-model="showInfoModal">
      <v-card>
        <v-card-title>
          {{ $t("billPreview.description") }}
        </v-card-title>
        <v-card-text>
          {{ showInfoModalItem.description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showInfoModal = false" color="primary" text>
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      showItem: undefined,
      dialog: false,
      showInfoModal: false,
      showInfoModalItem: {},
    };
  },
  created() {
    this.initData();
  },
  computed: {
    ...mapState({
      status: ({ billpreview }) => billpreview.status,
      items: ({ billpreview }) => billpreview.items,
      authResult: ({ session }) => session.authResult,
      sum: ({ billpreview }) => billpreview.sum,
    }),
    rowsPerPage() {
      return [
        10,
        25,
        50,
        { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
      ];
    },
  },
  methods: {
    ...mapMutations("session", {
      resetSession: "RESET",
    }),
    ...mapActions("session", ["setMode", "deleteLoginData"]),
    ...mapActions("billpreview", ["getBillData"]),
    ...mapActions("language", ["deleteCookies", "getLanguageList"]),
    initData() {
      if (
        this.authResult &&
        this.authResult.checkin &&
        this.authResult.checkin[0] &&
        this.authResult.checkin[0].name
      ) {
        this.getBillData({
          reservation_nr: this.authResult.checkin[0].reservation_nr,
        });
      } else {
        this.resetSession();
        this.deleteCookies();
        this.deleteLoginData();
        setTimeout(() => {
          this.$router.push({ name: "login", query: {} });
        });
      }
    },
  },
};
</script>
